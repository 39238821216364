import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InputLabel, MenuItem, FormControl, Select } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "99%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const SimpleSelect = params => {
  const { label, value, setValue } = params;
  const classes = useStyles();

  const handleChange = event => {
    setValue(event.target.value);
  };

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">{label}:</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={value}
          onChange={handleChange}
          label={label}
          width={500}
        >
          <MenuItem value="TF">Fridge</MenuItem>
          <MenuItem value="TB">Beer</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default SimpleSelect;
