import React, { useEffect } from "react";
import { navigate } from "@reach/router";
import { useAuth0 } from "../auth/react-auth0-spa";

import PageLoading from "../components/Loading/PageLoading";

const Public = () => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (loading) {
      return;
    }

    if (isAuthenticated) {
      navigate("/admin");
      return;
    }

    const fn = async () => {
      await loginWithRedirect({});
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect]);

  return (
    <>
      <PageLoading />
    </>
  );
};

export default Public;
