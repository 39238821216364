import React from "react";
import { render } from "react-dom";
import { Router } from "@reach/router";
import { Auth0Provider } from "./auth/react-auth0-spa";

import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import Public from "./layouts/Public";
import Admin from "./layouts/Admin";
import NotFound from "./layouts/NotFound";
import apiConfig from "./config";

import "typeface-roboto";

const {
  api: { auth }
} = apiConfig;

const theme = createMuiTheme({
  palette: {
    background: {
      default: "#F5F5F5"
    }
  }
});

const App = () => {
  return (
    <React.StrictMode>
      <Auth0Provider
        domain={auth.domain}
        client_id={auth.clientId}
        audience={auth.audience}
        redirect_uri={`${window.location.origin}/admin`}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <NotFound default />
            <Public path="/" />
            <Admin path="/admin/*" />
          </Router>
        </ThemeProvider>
      </Auth0Provider>
    </React.StrictMode>
  );
};

render(<App />, document.getElementById("root"));
