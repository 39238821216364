import React, { createContext, useState } from "react";
import AlertSnackbar from "./AlertSnackbar";

const AlertSnackbarContext = createContext();

let uniqueId = 2;

export const AlertSnackbarProvider = ({ children }) => {
  const [{ current, queue }, setState] = useState({ current: null, queue: [] });

  function createSnackbar(message, options = {}) {
    const id = uniqueId++;

    const props = {
      id,
      message,
      isOpened: true,
      severity: options.severity || "success",
      vertical: options.vertical || "bottom",
      horizontal: options.horizontal || "left",
      handleClose
    };

    if (current) {
      setState({ current, queue: queue.concat(props) });
    } else {
      setState({ queue, current: props });
    }

    return id;
  }

  function handleClose() {
    setState(currentState => ({
      ...currentState,
      current: { ...currentState.current, open: false }
    }));

    // time to snack close animation
    return setTimeout(openNext, 1000);
  }

  function openNext() {
    if (queue.length) {
      setState({ current: queue[0], queue: queue.slice(1) });
    } else {
      setState({ current: null, queue: [] });
    }
  }

  return (
    <AlertSnackbarContext.Provider value={{ createSnackbar }}>
      {current && (
        <AlertSnackbar
          key={current.id}
          {...current}
          handleClose={handleClose}
        />
      )}
      {children}
    </AlertSnackbarContext.Provider>
  );
};

export default AlertSnackbarContext;
