import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    height: "100vh",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  }
});

const PageLoading = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <CircularProgress />
      </div>
    </>
  );
};

export default PageLoading;
