import apiConfig from "../config";

const {
  api: { url: API_URL }
} = apiConfig;

const URLS = {
  tempBeer: `${API_URL}/api/fridge/temperature-beer`,
  tempBeerOn: `${API_URL}/api/fridge/temperature-beer-on`,
  tempBeerOff: `${API_URL}/api/fridge/temperature-beer-off`,
  tempFridge: `${API_URL}/api/fridge/temperature-fridge`,
  tempFridgeOn: `${API_URL}/api/fridge/temperature-fridge-on`,
  tempFridgeOff: `${API_URL}/api/fridge/temperature-fridge-off`,
  runningStatus: `${API_URL}/api/fridge/running-status`,
  regulation: `${API_URL}/api/fridge/regulation`
};

const _getRegulationRangeActions = regulationType => {
  const actions = { on: null, off: null };

  switch (regulationType) {
    case "TB":
      actions.on = "tempBeerOn";
      actions.off = "tempBeerOff";
      break;
    case "TF":
      actions.on = "tempFridgeOn";
      actions.off = "tempFridgeOff";
      break;
    default:
      throw new Error("Invalid regulationType given");
  }

  return actions;
};

const api = token => {
  async function get(endpointType) {
    const url = URLS[endpointType];

    if (!url) {
      throw new Error("Invalid endpointType given");
    }

    const result = await fetch(url, {
      headers: { Authorization: `Bearer ${await token}` }
    });

    const { data } = await result.json();

    return {
      value: data.value,
      lastUpdated: new Date()
    };
  }

  async function update(endpointType, payload) {
    const url = URLS[endpointType];

    if (!url) {
      throw new Error("Invalid endpointType given");
    }

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await token}`
      },
      body: JSON.stringify(payload)
    };

    const response = await fetch(url, requestOptions);

    return response.json();
  }

  async function getRegulationRange(regulationType) {
    const actions = _getRegulationRangeActions(regulationType);

    const [{ value: on }, { value: off }] = await Promise.all([
      get(actions.on),
      get(actions.off)
    ]);

    return {
      on,
      off
    };
  }

  async function setRegulationRange(regulationType, { on, off }) {
    const actions = _getRegulationRangeActions(regulationType);

    const response = await Promise.all([
      update(actions.on, { value: on }),
      update(actions.off, { value: off })
    ]);

    return response;
  }

  return {
    get,
    update,
    getRegulationRange,
    setRegulationRange
  };
};

export default api;
