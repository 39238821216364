import React, { useEffect } from "react";
import { Router } from "@reach/router";
import { useAuth0 } from "../auth/react-auth0-spa";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import Header from "../components/Header/Header";
import PageLoading from "../components/Loading/PageLoading";
import Dashboard from "../views/Admin/Dashboard/DashboardPage";
import Regulation from "../views/Admin/Regulation/RegulationPage";
import { AlertSnackbarProvider } from "../components/Snackbar/AlertSnackbar.context";

const useStyles = makeStyles({
  root: {
    padding: 30
  }
});

const Public = () => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();
  const classes = useStyles();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }

    loginWithRedirect({});
  }, [loading, isAuthenticated, loginWithRedirect]);

  return (
    <>
      {loading && <PageLoading />}

      {isAuthenticated && (
        <>
          <Header />
          <Container maxWidth="xl" className={classes.root}>
            <AlertSnackbarProvider>
              <Router>
                <Dashboard path="/" />
                <Regulation path="/regulation" />
              </Router>
            </AlertSnackbarProvider>
          </Container>
        </>
      )}
    </>
  );
};

export default Public;
