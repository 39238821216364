const config = {
  api: {
    url: process.env.API_URL,
    auth: {
      domain: process.env.AUTH_DOMAIN,
      clientId: process.env.AUTH_CLIENT_ID,
      audience: process.env.AUTH_AUDIENCE
    }
  }
};

export default config;
