import React, { useState, useEffect, useContext } from "react";
import { Link as RouterLink, navigate } from "@reach/router";
import { useAuth0 } from "../../../auth/react-auth0-spa";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  CircularProgress,
  Link,
  Typography
} from "@material-ui/core";

import SimpleSelect from "../../../components/Form/SimpleSelect";
import TemperatureSlider from "../../../components/Form/TemperatureSlider";
import AlertSnackbarContext from "../../../components/Snackbar/AlertSnackbar.context";

const useStyles = makeStyles({
  actionButton: {
    marginLeft: "auto",
    marginRight: 10,
    marginBottom: 5
  },
  content: {
    textAlign: "center"
  }
});

const Regulation = () => {
  const { api } = useAuth0();
  const { get, getRegulationRange, setRegulationRange, update } = api();

  const { createSnackbar } = useContext(AlertSnackbarContext);

  const classes = useStyles();

  const [regulation, setRegulation] = useState("");
  const [temperatureRange, setTemperatureRange] = useState(0);
  const [temperatureRangeDisabled, setTemperatureDisabled] = useState(true);
  const [loading, setLoading] = useState(true);

  async function updateRegulation() {
    const [off, on] = temperatureRange;

    try {
      await setRegulationRange(regulation, { on, off });
      await update("regulation", { value: regulation });

      createSnackbar("Regulation updated successfully!");

      return navigate("/admin");
    } catch (e) {
      createSnackbar("An error occured while updating regulation!", {
        severity: "error"
      });
    }
  }

  useEffect(() => {
    // regulation type
    get("regulation")
      .then(({ value }) => {
        setRegulation(value);
      })
      .catch(err => {
        createSnackbar("Failed to fetch data.", {
          severity: "warning"
        });

        setLoading(false);
      });
  }, []);

  useEffect(() => {
    // disable selection when regulation is off
    if (regulation === "") {
      setTemperatureDisabled(true);
      return;
    }

    // running temperature range
    getRegulationRange(regulation)
      .then(({ on, off }) => {
        setTemperatureRange([off, on]);
        setTemperatureDisabled(false);
      })
      .catch(err => {
        createSnackbar("Failed to fetch data.", {
          severity: "warning"
        });
      })
      .finally(() => setLoading(false));
  }, [regulation]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <form
              onSubmit={e => {
                e.preventDefault();

                updateRegulation();
              }}
            >
              <CardHeader
                title="Update Regulation"
                titleTypographyProps={{ color: "textSecondary" }}
              />

              {loading && (
                <CardContent className={classes.content}>
                  <CircularProgress />
                </CardContent>
              )}
              {!loading && (
                <>
                  <CardContent>
                    <Typography color="textSecondary"></Typography>
                    <SimpleSelect
                      label="Regulate by"
                      setValue={setRegulation}
                      value={regulation}
                    />
                    <TemperatureSlider
                      label="Running temperature range"
                      setValue={setTemperatureRange}
                      value={temperatureRange}
                      disabled={temperatureRangeDisabled}
                    />
                  </CardContent>
                </>
              )}
              <CardActions>
                <Link
                  component={RouterLink}
                  to="/admin"
                  underline="none"
                  className={classes.actionButton}
                >
                  <Button size="small">Cancel</Button>
                </Link>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  color="primary"
                  className={classes.actionButton}
                  disabled={loading}
                >
                  Save
                </Button>
              </CardActions>
            </form>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Regulation;
