import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Slider, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: "100%",
    padding: 20
  }
});

function valuetext(value) {
  return `${value}°C`;
}

const marks = [
  {
    value: 0,
    label: "0°C"
  },
  {
    value: 5,
    label: "5°C"
  },
  {
    value: 10,
    label: "10°C"
  },
  {
    value: 15,
    label: "15°C"
  },
  {
    value: 20,
    label: "20°C"
  },
  {
    value: 25,
    label: "25°C"
  },
  {
    value: 30,
    label: "30°C"
  }
];

const TemperatureSlider = props => {
  const { label, value, setValue, disabled } = props;
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    const diff = newValue[1] - newValue[0];

    if (diff < 0.5) {
      // precision error quick fix
      newValue[0] = Math.round((newValue[1] - 0.5) * 100) / 100;
    }
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Typography color="textSecondary" gutterBottom>
        {label}:
      </Typography>
      <Slider
        value={value}
        onChange={handleChange}
        disabled={disabled}
        valueLabelDisplay="on"
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
        step={0.1}
        marks={marks}
        min={0}
        max={30}
      />
    </div>
  );
};

export default TemperatureSlider;
