import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { Card, CardContent, CardHeader } from "@material-ui/core";

const DashboardLiveChart = props => {
  const { title, data } = props;

  const defaultOptions = {
    title: false,
    xAxis: {
      categories: []
    },
    yAxis: {
      title: {
        text: "Temperature (°C)"
      }
    },
    series: [
      {
        name: "Running status",
        step: "right",
        color: "#D72A05",
        data: []
      },
      {
        name: "Fridge",
        color: "#4873E8",
        data: []
      },
      {
        name: "Beer",
        color: "#F0C63E",
        data: []
      }
    ]
  };

  const [options, setOptions] = useState(defaultOptions);

  useEffect(() => {
    if (!data.length) {
      return;
    }

    const [timeVal, runningVal, fridgeVal, beerVal] = data;

    const newValues = {
      xAxis: {
        categories: [...options.xAxis.categories.slice(-50), timeVal]
      },
      plotOptions: {
        line: {
          marker: {
            // hide marker when more than one point provided
            enabled: !options.xAxis.categories.length
          }
        }
      },
      series: [
        {
          data: [...options.series[0].data.slice(-50), runningVal]
        },
        {
          data: [...options.series[1].data.slice(-50), fridgeVal]
        },
        {
          data: [...options.series[2].data.slice(-50), beerVal]
        }
      ]
    };

    setOptions(newValues);
  }, [data]);

  return (
    <Card>
      <CardHeader
        title={title}
        titleTypographyProps={{ color: "textSecondary" }}
      />
      <CardContent>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </CardContent>
    </Card>
  );
};

export default DashboardLiveChart;
