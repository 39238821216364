import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
  IconButton,
  CircularProgress
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { formatDateTime } from "../../utils/dateTime";

const useStyles = makeStyles({
  title: {
    fontSize: 22
  },
  updated: {
    fontSize: 12
  },
  content: {
    textAlign: "center"
  }
});

const StatusCard = props => {
  const { title, value, unit, lastUpdated, action, loading } = props;
  const formatedDate = formatDateTime(lastUpdated);
  const classes = useStyles();

  const cardAction = action && (
    <IconButton aria-label="settings">
      <SettingsIcon />
    </IconButton>
  );

  const CardContentValue = ({ loading }) => {
    if (!loading) {
      return (
        <Typography align="center" variant="h3">
          {value} {unit}
        </Typography>
      );
    } else {
      return <CircularProgress />;
    }
  };

  return (
    <Card>
      <CardHeader
        action={cardAction}
        title={title}
        titleTypographyProps={{ color: "textSecondary" }}
      />
      <CardContent className={classes.content}>
        <CardContentValue loading={loading} />
      </CardContent>
      <CardActions>
        <Typography
          className={classes.updated}
          color="textSecondary"
          gutterBottom
        >
          Updated: {formatedDate}
        </Typography>
      </CardActions>
    </Card>
  );
};

export default StatusCard;
