import React, { useState, useEffect, useContext } from "react";
import { Link as RouterLink, globalHistory } from "@reach/router";
import { useAuth0 } from "../../../auth/react-auth0-spa";

import { Grid, Link } from "@material-ui/core";
import StatusCard from "../../../components/Card/StatusCard";
import AlertSnackbarContext from "../../../components/Snackbar/AlertSnackbar.context";
import DashboardLiveChart from "../../../components/Chart/DashboardLiveChart";
import { formatTime } from "../../../utils/dateTime";

const Dashboard = () => {
  const { api } = useAuth0();
  const { get } = api();

  const { createSnackbar } = useContext(AlertSnackbarContext);

  const defaultValue = { value: "-" };

  const [tempBeer, setTempBeer] = useState(defaultValue);
  const [tempFridge, setTempFridge] = useState(defaultValue);
  const [runningStatus, setRunningStatus] = useState(defaultValue);
  const [regulation, setRegulation] = useState(defaultValue);
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (function updateData(showFetchError = true) {
      Promise.all([
        get("tempBeer"),
        get("tempFridge"),
        get("runningStatus"),
        get("regulation")
      ])
        .then(([beerData, fridgeData, runningData, regulationData]) => {
          const { value: runningValue } = runningData;
          runningData.value = runningData.value === 0 ? "OFF" : "ON";

          regulationData.value =
            regulationData.value === "TF" ? "Fridge" : "Beer";

          // update cards
          setTempBeer(beerData);
          setTempFridge(fridgeData);
          setRunningStatus(runningData);
          setRegulation(regulationData);

          // update chart
          const formatedTime = formatTime(beerData.lastUpdated);

          setChartData([
            formatedTime,
            runningValue,
            fridgeData.value,
            beerData.value
          ]);

          showFetchError = true;
        })
        .catch(err => {
          if (showFetchError) {
            createSnackbar("Failed to fetch data.", {
              severity: "warning"
            });

            // prevent showing error when fetch fails multiple time
            showFetchError = false;
          }
        })
        .finally(() => setLoading(false));

      const timeout = setTimeout(() => updateData(showFetchError), 10000);

      globalHistory.listen(({ action }) => {
        if (action === "PUSH") {
          clearTimeout(timeout);
        }
      });
    })();
  }, []);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={3} sm={6} xs={12}>
          <StatusCard title="Beer" unit="°C" loading={loading} {...tempBeer} />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <StatusCard
            title="Fridge"
            unit="°C"
            loading={loading}
            {...tempFridge}
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <StatusCard
            title="Fridge Status"
            loading={loading}
            {...runningStatus}
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <Link component={RouterLink} to="regulation" underline="none">
            <StatusCard
              title="Regulation by"
              loading={loading}
              {...regulation}
              action={true}
            />
          </Link>
        </Grid>
        <Grid item xs={12}>
          <DashboardLiveChart title="Live Data" data={chartData} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
