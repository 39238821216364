import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    height: "100vh",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  }
});

const NotFound = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Typography variant="h1" component="h2">
          404
        </Typography>
        <Typography variant="h5" component="h3">
          This page could not be found.
        </Typography>
      </div>
    </>
  );
};

export default NotFound;
