const FORMAT_DATE_TIME = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric"
};

const FORMAT_TIME = {
  hour: "numeric",
  minute: "numeric",
  second: "numeric"
};

const LOCALE = "de-DE";

const formatDateTime = dateTime => {
  const formatedDate = dateTime
    ? new Intl.DateTimeFormat(LOCALE, FORMAT_DATE_TIME).format(dateTime)
    : "/";

  return formatedDate;
};

const formatTime = dateTime => {
  const formatedTime = dateTime
    ? new Intl.DateTimeFormat(LOCALE, FORMAT_TIME).format(dateTime)
    : "/";

  return formatedTime;
};

export { formatDateTime, formatTime };
